<template>
  <div class="church-presentation-template">
    <page-body class>
      <section class="section-1 bg-block mb-4">
        <b-container class="">
          <b-row class="">
            <b-col cols="12">
              <h1 class="sm">{{ translations.tcConversationInfo }} &mdash; {{ conversation.selectedStateCountry &&
                conversation.selectedStateCountry.text ? conversation.selectedStateCountry.text : '' }}</h1>
              <hr />
            </b-col>
          </b-row>
        </b-container>
        <b-container>
          <b-row class="mb-2">
            <b-col class="dateTime">
              <div class="h-style-1">{{ translations.tcEventType }}</div>
              {{ eventTypeLookup }}
              <div class="h-style-1">{{ translations.tcEventTitle }}</div>
              <span class="">{{ conversation.eventTitle }}</span>
              <div class="h-style-1">{{ translations.tcEventDays }}</div>
              <div class="calendar" v-if="!!conversation.startEndDate">
                <iCalendar />
                {{
                  new Date(conversation.startEndDate.startDate + 'T01:02:03').toLocaleString(this.prefCulture, {
                    dateStyle: 'long'
                  })
                }}&nbsp;
                <span v-if="conversation.startEndDate.startDate !== conversation.startEndDate.endDate">&nbsp; &mdash;
                  {{
                    new Date(conversation.startEndDate.endDate + 'T01:02:03').toLocaleString(this.prefCulture, {
                      dateStyle: 'long'
                    })
                  }}</span>
              </div>
              <div class="calendar" v-if="!!conversation.startTime">
                <iClock class="clockIcon"></iClock>
                {{ formatTimeString(conversation.startTime) }} &mdash; {{ formatTimeString(conversation.endTime) }}
              </div>
            </b-col>
            <b-col>
              <div class="h-style-1">{{ translations.tcGeographicDistribution }}</div>
              <span class="bold-text">{{ conversation.distributionSelected && conversation.distributionSelected.text ?
                conversation.distributionSelected.text : '' }}</span>
              <div v-if="conversation.distributionSelected && conversation.distributionSelected.key === 'areas'">
                <div v-for="area in conversation.selectedAreas" :key="area.org_key">
                  {{ area.org_name }}
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col>
              <div class="h-style-1">{{ translations.tcEducationalUnit }}</div>
              <span>{{ educationalUnit.name }}<br>
                {{ educationalUnit.email }}<br>
                {{ educationalUnit.phone }}<br>
              </span>
              <div class="h-style-1">{{ translations.tcInstructor }}</div>
              <span>{{ instructor.name }}<br>
                {{ instructor.email }}<br>
                {{ instructor.phone }}<br>
              </span>
            </b-col>
            <b-col>
              <div class="h-style-1">{{ translations.tcLocalContact }}</div>
              <span>
                {{ localContact.name }}<br>
                {{ localContact.email }}<br>
                {{ localContact.phone }}<br>
              </span>
              <div class="h-style-1">{{ translations.tcLocation }}</div>
              <span v-html="locationDisplay"></span>
            </b-col>
          </b-row>
          <b-row  v-if="conversationsICanSee(secured_controls.attendees_section)">
            <b-col>
              <div class="h-style-1">{{ translations.tcAttendees }}</div>
            </b-col>
          </b-row>
          <b-row class="mb-2" v-if="conversationsICanSee(secured_controls.attendees_section)">
            <b-col>
              <span class="bold-text">{{ translations.tcMembers }}</span>
              <div v-if="conversation.attendeesMembers && conversation.attendeesMembers.length > 0">
                <div v-for="member in conversation.attendeesMembers" :key="member.value" class="attendee-display">
                  {{ member.text }}<br>
                  <span v-if="member.email">{{ member.email }}<br></span>
                  <span v-if="member.phone">{{  member.phone }}<br></span>
                </div>
              </div>
              <div v-else>
                <span class="italic-text">{{ translations.tcNoneSelected }}</span>
              </div>
            </b-col>
            <b-col>
              <span class="bold-text">{{ translations.tcNonMembers }}</span>
              <div v-if="conversation.attendeesNonmembers && conversation.attendeesNonmembers.length > 0">
                <div v-for="nonmember in conversation.attendeesNonmembers" :key="nonmember.value" class="attendee-display">
                  {{ nonmember.text }}<br>
                  <span v-if="nonmember.email">{{ nonmember.email }}<br></span>
                  <span v-if="nonmember.phone">{{  nonmember.phone }}<br></span>
                </div>
              </div>
              <div v-else>
                <span class="italic-text">{{ translations.tcNoneSelected }}</span>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="mt-3">
              <b-button variant="primary" class="mr-3" @click="handleBackClick()">
                {{ translations.tcBack }}
              </b-button>
              <b-button variant="secondary"
                v-if="conversationsICanSee(secured_controls.delete_button)" this needs to be awaited some how
                @click="handleDeleteButtonClick" class="flex-0 mr-0 mr-sm-3 w-100-sd mb-3 mb-sm-0">
                {{ translations.tcDelete }}
              </b-button>
              <b-button variant="primary"
                v-if="conversationsICanSee(secured_controls.edit_button)"
                class="mr-3"
                @click="handleEditClick()">
                {{ translations.tcEdit }}
              </b-button>
              <b-button variant="primary"
                v-if="conversationsICanSee(secured_controls.edit_button)"
                class="mr-3"
                @click.prevent="downloadReport(exportConversationDetailRoute, 'conversationsDetails', 'pdf')">
                {{ translations.tcExport }}
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </section>
    </page-body>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import { calendarMixin } from '@/mixins/calendarMixin'
import iCalendar from '@/assets/svgs/iCalendar.vue'
import iClock from '@/assets/svgs/iClock.vue'
import { numericMixin } from '@/mixins/numericMixin'
import pageBody from '@/components/page-components/PageBody.vue'
import { reportDownloadMixin } from '@/mixins/reportDownloadMixin'
import { securityMixin } from '@/mixins/securityMixin'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'conversation-info',
  mixins: [translationMixin, calendarMixin, numericMixin, securityMixin, reportDownloadMixin],
  data() {
    return {
      educationalUnit: {
        ind_key: '',
        name: '',
        phone: '',
        email: '',
      },
      instructor: {
        ind_key: '',
        name: '',
        phone: '',
        email: '',
      },
      localContact: {
        ind_key: '',
        name: '',
        phone: '',
        email: '',
      },
      isEditMode: false,
      local_conversation: {
        members: [],
        nonmembers: []
      },
      secured_controls: {
        edit_button: '47fb16ec-c551-48eb-9e73-0d585d9c14a8',
        delete_button: '7F228449-27A0-4090-A85E-701FF393EE2D',
        attendees_section: 'EF524E52-B6F3-4FB5-A2C1-E75E45A99545'
      },
      translations: {}
    }
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('common.calendar-event-type-conversation')
      ]).then(results => {
        this.stripReadableText(results[1])
        const translatedText = {
          ...results[1],
          common: { ...this.translations.common }
        }
        this.$set(this.translations, 'components', translatedText)
        //this.loadTranslations()
      })
      await this.page_load()
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },

  methods: {
    ...mapActions({
      clearConversation: 'conversations/clearConversation',
      deleteConversation: 'conversations/deleteConversation',
      loadConversationDetailsByMeetingKey: 'conversations/loadConversationDetailsByMeetingKey',
      loadEducationalUnits: 'conversations/loadEducationalUnits',
      loadExportConversationDetailRoute: 'conversations/loadExportConversationDetailRoute',
      loadInstructors: 'conversations/loadInstructors',
      loadMeetingTypes: 'conversations/loadMeetingTypes',
      loadCountryByOFTB: 'conversations/loadCountryByOFTB',
      setLoadingStatus: 'menu/setLoadingStatus',
      updateConversationEventDetails: 'conversations/updateConversationEventDetails'
    }),
    async page_load() {
      try {
        this.setLoadingStatus(true)

        const meetingTypePayload = {
          type: 'conversation',
          lang_key: this.demograph.language_key
        }
        const euPayload = {
          org_key: this.officerToolbarSelected.country_state,
          lang_key: this.demograph.language_key
        }
        await Promise.all([
          this.loadMeetingTypes(meetingTypePayload),
          this.loadEducationalUnits(euPayload),
          this.loadInstructors(euPayload),
          this.loadCountryByOFTB(euPayload),
          this.loadConversationDetailsByMeetingKey(this.userSelectedMeetingKey),
          this.loadExportConversationDetailRoute(this.userSelectedMeetingKey)
        ]).then(() => {
          this.educationalUnit.name = this.conversation.educationalUnitKey ? this.educational_units.find(element => element.ind_key === this.conversation.educationalUnitKey).name : this.translations.tcNotEntered
          this.educationalUnit.ind_key = this.conversation.educationalUnitKey ? this.conversation.educationalUnitKey : ''
          this.educationalUnit.phone = this.conversation.educationalUnitKey ? this.conversation.educationalUnit.phone : ''
          this.educationalUnit.email = this.conversation.educationalUnitKey ? this.conversation.educationalUnit.email : ''
          this.instructor.name = this.conversation.instructorKey ? [...this.instructors, ...this.educational_units].find(element => element.ind_key ===
              this.conversation.instructorKey).name : this.translations.tcNotEntered
          this.instructor.ind_key = this.conversation.instructorKey ? this.conversation.instructorKey : ''
          this.instructor.phone = this.conversation.instructorKey ? this.conversation.instructor.phone : ''
          this.instructor.email = this.conversation.instructorKey ? this.conversation.instructor.email : ''
          this.localContact.name = this.conversation.localContact.value ? this.conversation.localContact.text : this.translations.tcNotEntered
          this.localContact.ind_key = this.conversation.localContact.value ? this.conversation.localContact.value : ''
          this.localContact.phone = this.conversation.localContact.value ? this.conversation.localContact.phone : ''
          this.localContact.email = this.conversation.localContact.value ? this.conversation.localContact.email : ''
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async handleBackClick() {
      // route to the previous page in history
      this.$router.go(-1)
    },
    async handleEditClick() {
      this.$router.push({ name: 'conversations-add' })
    },
    async handleDeleteButtonClick() {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        text: this.translations.	tcConversationWillBeDeletedOk,
        confirmButtonText: this.translations.tcOk || 'Ok',
        cancelButtonText: this.translations.tcCancel || 'Cancel',
      }).then((result) => {
        if (result.value) {
          let payload = {
            cvs_key: this.conversation.conversationKey,
            user: this.demograph.member_number,
          }
          this.deleteTheConversation(payload)
        }
      })
    },
    async deleteTheConversation(payload) {
      let result = null
      await Promise.all([this.setLoadingStatus(true), (result = await this.deleteConversation(payload))]).then(
        (result) => {
          this.setLoadingStatus(false)
          this.$swal({
            icon: result[1] === true ? 'success' : 'error',
            text: result[1] === true ? this.translations.tcConversationDeleted : this.translations.tcErrorDuringDelete,
            confirmButtonText: this.translations.tcOk || 'Ok',
          }).then((rtn) => {
            if (rtn.value) {
              // commenting out the following so it just redirects to the conversation list for now
              // let linkBack = this.userRouterBackLink
              // this.setRouterBackLink('')
              // if (linkBack.length > 0) {
              //   this.$router.push({ name: linkBack })
              // } else {
              this.$router.push({ name: 'conversation-list' })
              this.clearConversation()
              //}
            }
          })
        }
      )
    },
  },
  computed: {
    ...mapGetters({
      conversation: 'conversations/conversation',
      demograph: 'user/demograph',
      educational_units: 'conversations/educational_units',
      exportConversationDetailRoute: 'conversations/exportConversationDetailRoute',
      instructors: 'conversations/instructors',
      meeting_types: 'conversations/meeting_types',
      officerToolbarSelected: 'user/officerToolbarSelected',
      otfbCountry: 'conversations/otfbCountry',
      prefCulture: 'user/userPreferredCulture',
      userSelectedConversationKey: 'user/userSelectedConversationKey',
      userSelectedMeetingKey: 'user/userSelectedMeetingKey',
    }),
    eventTypeLookup() {
      var et = this.eventTypeTranslated.find(element => element.value === this.conversation.eventType)
      if (et) {
        return et.text
      } else {
        return ''
      }
    },
    eventTypeTranslated() {
      let options = this.meeting_types.map(item => {
        let text = item.mtt_description_text
        if (!!this.translations.common && !!this.translations.common['calendar-event-type-conversation']) {
          text = this.translations.common['calendar-event-type-conversation'][item.mtt_key] || item.mtt_description_text
        }
        const translatedItem = { value: item.mtt_key, text: text }
        return translatedItem
      })
      return options
    },
    locationDisplay() {
      var location = '';
      if (this.conversation.location && this.conversation.location.org_name) {
        location = `${this.conversation.location.org_name}<br> ${this.conversation.location.adr_line1} <br>${this.conversation.location.adr_city}, ${this.conversation.location.adr_state} ${this.conversation.location.adr_postal_code}`
      }
      if (this.conversation.virtualLocation && this.conversation.virtualLocation.lvt_name) {
        location = `${this.conversation.virtualLocation.lvt_name} <br>${this.conversation.virtualLocation.lvt_note}`;
      }
      return location;
    },
  },
  components: {
    iCalendar: iCalendar,
    iClock: iClock,
    pageBody
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

.page-body {
  padding: 80px 15px;
}

.bg-block {
  padding: 24px;
}

.bold-text {
  font-weight: bold;
}

.italic-text {
  font-style: italic;
}
.attendee-display
{
  margin-bottom: 10px;
}
.dateTime {
  div.calendar {
    display: flex;
    align-items: center;

    svg.clockIcon {
      margin-right: 15px;

      path {
        fill: #636363 !important;
      }
    }
  }
}

.calendar {
  display: flex;
  align-items: center;

  svg {
    margin-right: 15px;

    path {
      fill: #636363 !important;
    }
  }

  .clockIcon {
    margin-right: 15px;

    path {
      fill: #636363 !important;
    }
  }
}
</style>